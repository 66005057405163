import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Content from '@solid-ui-blocks/Content/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import theme from './_theme'

const Terms = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <div style={{ textAlign: 'center', paddingRight: '5rem', paddingLeft: '5rem' }}>
      <div>
    <h2>Zelten LLC Terms of Use</h2>

    <p><strong>Effective Date:</strong> 12/20/2023</p>

    <p>These Terms of Use ("Terms") govern your use of the Zelten LLC website and services. By accessing or using our website, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please do not use our website.</p>

    <p><strong>Use of Our Website:</strong></p>
    <p>You agree to use our website for lawful purposes only and in a way that does not infringe on the rights of, restrict, or inhibit anyone else's use and enjoyment of the site.</p>

    <p><strong>Intellectual Property:</strong></p>
    <p>The content on our website, including text, graphics, logos, and images, is the intellectual property of Zelten LLC and is protected by copyright laws. You may not use, reproduce, or distribute our content without our explicit permission.</p>

    <p><strong>User Accounts:</strong></p>
    <p>If you create an account on our website, you are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>

    <p><strong>Disclaimer:</strong></p>
    <p>Our website is provided "as is" without any warranties, expressed or implied. We do not guarantee the accuracy, completeness, or timeliness of the information and materials found on our site.</p>

    <p><strong>Limitation of Liability:</strong></p>
    <p>Zelten LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>

    <p><strong>Changes to These Terms:</strong></p>
    <p>We reserve the right to update or change these Terms at any time. Your continued use of our website after any such modifications will constitute your acknowledgment of the modified Terms.</p>

    <p>If you have any questions about these Terms of Use, please contact us at <a href="mailto:info@zeltenstudio.com">info@zeltenstudio.com</a>.</p>

    <p>Thank you for using Zelten LLC.</p>

  </div>
  </div>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageTermsBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/services", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Terms
